<template>
	<main class="page-container">
		<!-- s: 상단 영역/검색 -->
		<section class="scomm-section">
			<h3 class="sr-only">검색창</h3>
			<div class="container">
				<div v-if="this.pageType === 'M'" class="scomm-main-slogan flex-both align-items-end">
					<div class="flex-1">
						<p class="scomm-main-slogan__strong">
							소상공인 지원사업 <br />
							정보교류는 여기서!
						</p>
						<p class="scomm-main-slogan__desc">
							내가 참여하는 소상공인 지원사업에 대한 소식도 만나고,<br />
							다른 소상공인들과 다양한 정보를 나누며 함께 성장할 수 있어요
						</p>
					</div>
					<!-- <div class="flex-0">
						<a href="#" class="link-text">커뮤니티 운영정책</a>
					</div> -->
				</div>
				<div v-if="this.pageType === 'M'" class="scomm-main-pic">
					<ol class="scomm-main-pic__list">
						<li>
							<strong>
								<em>1</em>
								<span>소상공인 지원사업 참여하기</span>
							</strong>
							<p>판판대로를 통해 진행하는 지원사업에 최종선정이 된 소상공인들이 참여할 수 있어요</p>
						</li>
						<li>
							<strong>
								<em>2</em>
								<span>커뮤니티 가입하기</span>
							</strong>
							<p>사업담당자를 통해 안내받은 커뮤니티에 가입하기를 하면 담당자가 승인을 해드려요</p>
						</li>
						<!-- <li>
							<strong>
								<em>&nbsp;</em>
								<span>&nbsp;</span>
							</strong>
							<p>&nbsp;</p>
						</li> -->
						<!-- 7월 마이페이지 오픈시 노출예정 -->
						<li>
							<strong>
								<em>3</em>
								<span>커뮤니티 이용하기</span>
							</strong>
							<p>새로 개편되는 가치삽시다 마이페이지(2023.7. 예정)를 통해 내가 가입한 커뮤니티를 손쉽게 둘러보고 알림을 확인할 수 있어요</p>
						</li>
						<!-- 7월 마이페이지 오픈시 노출예정 -->
					</ol>
				</div>
				<div class="scomm-main-search" @keyup.enter="getSCommunityList(1)">
					<fieldset>
						<legend class="sr-only">커뮤니티 검색</legend>
						<input v-model="srchText" type="search" class="scomm-main-search__input" placeholder="커뮤니티를 검색해 보세요!    ex) 상세페이지 제작지원" />
						<button type="submit" class="scomm-main-search__submit" @click="getSCommunityList(1)"><i class="icon-scomm-search"></i><span class="sr-only">검색</span></button>
					</fieldset>
				</div>
			</div>
		</section>
		<!-- e: 상단 영역/검색 -->
		<!-- s: 조회 결과 -->
		<section class="scomm-section scomm-section--bg">
			<div class="container">
				<h3 class="sr-only">조회 결과</h3>
				<!-- 메인 타입 카운트 노출 -->
				<p v-if="this.pageType === 'M'" class="scomm-result-desc">
					소상공인 커뮤니티 총 <strong>{{ this.totalCount }}</strong> 개
				</p>
				<!-- 검색 타입 카운트 노출 -->
				<p v-else class="scomm-result-desc">
					<span class="keyword">‘{{ this.srchText }}’</span>으로 검색한 결과 총 <strong class="color-orange">{{ this.totalCount }}</strong> 개
				</p>
				<!-- 메인 타입 조회 결과가 없는 경우 -->
				<div v-if="this.pageType === 'M' && this.totalCount === 0" class="no-results-found">
					<p class="text">등록된 커뮤니티가 없습니다</p>
				</div>
				<!-- 검색 타입 조회 결과가 없는 경우 -->
				<div v-if="this.pageType === 'S' && this.totalCount === 0" class="no-results-found">
					<p class="text">
						<span class="color-orange">"{{ this.srchText }}"</span>에 대한 검색결과가 없습니다.
					</p>
				</div>
				<!-- scomm-card -->
				<article v-for="(item, idx) in this.scommunityItems" class="scomm-card" :key="idx">
					<a @click="goSCommunity(item.cmntId)">
						<div class="scomm-card-item flex-both">
							<div class="flex-l">
								<span class="scomm-card-thumb">
									<img :src="item.rprsImgPtUrl" alt="이미지 정보" />
								</span>
							</div>
							<div class="flex-r">
								<p class="scomm-card-path">{{ getCategoryTitle(item) }}</p>
								<div class="scomm-card-item__text flex-both">
									<div class="flex-l">
										<h4 class="scomm-card-title">{{ item.cmntNm }}</h4>
										<p class="scomm-card-desc">{{ item.cmntDsc }}</p>
									</div>
									<div class="flex-r">
										<dl class="scomm-card-info">
											<dt>문의처</dt>
											<dd>담당자(정) {{ item.cmntOwnNm }}</dd>
										</dl>
										<dl class="scomm-card-info">
											<dt>유선 연락처</dt>
											<dd>{{ getTelNo(item.cmntOwnTelNo) }}</dd>
										</dl>
									</div>
								</div>
							</div>
						</div>
					</a>
				</article>
				<!-- //scomm-card -->
			</div>
		</section>
		<!-- e: 조회 결과 -->
		<!-- pagination -->
		<s-comnnunity-pagination page-name="ScommunityMain" :page-no="pageNo" :page-size="pageSize" :page-list-size="pageListSize" :total-count="totalCount" :query="queries" :parent-method-name="pagingMethodName" @getSCommunityList="getSCommunityList"></s-comnnunity-pagination>
		<!-- //pagination -->
	</main>
</template>

<script>
import { ACT_GET_SCOMMUNITY_LIST, ACT_GET_SCOMMUNITY_PRHB_WRDS } from '../../../store/_act_consts';
import { MUT_SHOW_CONFIRM, MUT_SET_RETURN_ROUTE, MUT_SET_SCOMMUNITY_PRHB_WRDS } from '../../../store/_mut_consts';
import { getCheckItems, setPaging, phoneNumberMask, lengthCheck, getItems } from '../../../assets/js/utils';
import { mapGetters } from 'vuex';
import SComnnunityPagination from '@/components/scommunity/SCommunityPagination.vue';

export default {
	name: 'ScommunityMain',
	components: {
		SComnnunityPagination,
	},
	data: () => ({
		pageType: 'M',
		pagingMethodName: 'getSCommunityList',
		scommunityItems: [],
		srchText: '',
		totalCount: 100,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 1,
	}),
	computed: {
		...mapGetters('auth', ['session', 'isAuth']),
		...mapGetters('common', ['isMobile']),
		...mapGetters('scommunity', ['prhbWrds']),
		queries() {
			const result = {};
			if (this.srchText) result.name = this.srchText;
			return result;
		},
	},
	watch: {},
	created() {
		this.getSCommunityList(1);
		if (this.prhbWrds.length == 0) {
			this.getSCommunityPrhbWrds();
		}
	},
	mounted() {},
	methods: {
		getSCommunityList(no) {
			this.$store
				.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_LIST}`, {
					dateType: 'A',
					startYmd: '',
					endYmd: '',
					srchType: this.srchText ? 'C' : 'A',
					srchText: this.srchText,
					pageNo: no,
					pageSize: this.pageSize,
				})
				.then((res) => {
					this.pageType = this.srchText ? 'S' : 'M';
					this.scommunityItems = getCheckItems(res);
					setPaging(this, res);
				});
		},
		getTelNo(telNo) {
			return phoneNumberMask(telNo);
		},
		goSCommunity(cmntId) {
			if (typeof this.session.userId == 'undefined') {
				// alert('로그인이 필요합니다.');
				// this.$router.push({ name: 'Login' });
				// return false;
				this.$store.commit(`common/${MUT_SHOW_CONFIRM}`, {
					title: '회원가입 및 로그인이 필요합니다.',
					html: true,
					showCancelButton: true,
					yesfunc: () => {
						this.$store.commit(`common/${MUT_SET_RETURN_ROUTE}`, this.$route.path);
						this.$router.push({ name: 'Login' });
					},
				});
			} else {
				this.$router.push(`/scommunity/${cmntId}`);
			}
		},
		getSCommunityPrhbWrds() {
			this.$store.dispatch(`scommunity/${ACT_GET_SCOMMUNITY_PRHB_WRDS}`, {}).then((res) => {
				if (lengthCheck(res)) {
					this.$store.commit(`scommunity/${MUT_SET_SCOMMUNITY_PRHB_WRDS}`, getItems(res));
				}
			});
		},
		getCategoryTitle(item){
			let cateTitle = '';
			cateTitle = item.sboBizYmdVal;
			if(item.sboBizVal){
				cateTitle = cateTitle + ' > ' + item.sboBizVal;
			}
			if(item.sboBizDtlVal){
				cateTitle = cateTitle + ' > ' + item.sboBizDtlVal;
			}
			if(item.sboBizDdtlVal){
				cateTitle = cateTitle + ' > ' + item.sboBizDdtlVal;
			}
			if(item.sboBizNoticeVal){
				cateTitle = cateTitle + ' > ' + item.sboBizNoticeVal;
			}
			return cateTitle;
		}
	},
};
</script>
